import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getReserveByIdQuery, getReserveSubOrdersByIdQuery } from "../../../queries";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { prettify } from "../ui/prettify";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { Controller } from "react-hook-form";
import Select from "react-select";
import * as moment from "moment";
import { Input } from "../../../Components/atoms/input";
import { ToastContainer, toast } from "react-toastify";
import { ReserveSubOrdersByIdPut } from "../../../api";
import * as Yup from 'yup';
import Textfield from "../ui/TextField";

const form = {
  state: 0,
  reason: "",
};
const ReserveSingle = () => {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    reason: Yup.string()
    .nullable()  
    .when('state.value', {
      is: 4,  
      then: Yup.string().required(t("required_field_error")),  
      otherwise: Yup.string(), 
    }),
  });
  const { id, subOrderId } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const clients = useQueryClient()
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    watch
  } = useHookForm(form, schema);
  const { data, isLoading } = useQuery({
    ...getReserveSubOrdersByIdQuery(id, subOrderId),
  });

  const {data: client, isLoading: clientLoading} = useQuery({
    ...getReserveByIdQuery(id)
  })

  

  const calculateOrderTotalPrice = (order) => {
    
    let total = 0;
   
      order?.result?.items?.forEach((item) => {
        const itemPrice = item?.variation?.prices?.find(
          (price) => price.type === "Price"
        )?.value;
        if (itemPrice) {
          total += itemPrice * item?.count;
        }
      });
   
    return total;
  };

  useEffect(() => {
    if (data) {
      const value = {
        state:
          data.result.state !== null
            ? {
                value: data.result.state,
                label:
                  data.result.state === 0
                    ? t("reserve_created")
                    : data.result.state === 1
                    ? t("reserve_undrway")
                    : data.result.state === 2
                    ? t("reserve_sent")
                    : data.result.state === 3
                    ? t("reserve_complated")
                    : data.result.state === 4
                    ? t("reserve_cancelled")
                    : t("reserve_refund"),
              }
            : null,
            reason: data.result.reason || ""
      };
      reset(value);
    }
  }, [data, reset]);



  const onSubmit = handleSubmit(async (res) => {
    setLoading(true);
    const state = res.state.value;
    const datas = {
      state: state,
      reason: res.reason || "",
    };

    try {
      await ReserveSubOrdersByIdPut(id, subOrderId, datas);

     
      
      toast(t("Заказ успешно изменен"), {
        type: "success",
        theme: "colored",
        position: "top-center",
      });
      clients.invalidateQueries({ queryKey: ["orders2-single"] });
      clients.invalidateQueries({ queryKey: ["orders2"] });
      setTimeout(() => {
        navigate("/reserve");
      }, 2000);
    } catch (error) {
      toast(error.data?.error?.errorMessage || error.data.message, {
        type: "error",
        theme: "colored",
        position: "top-center",
      });
    } finally {
      setLoading(false);
    }
  });
  const totalPrice = calculateOrderTotalPrice(data);
  return (
    <div className="page-content">
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      ) : (
        <div>
          <ToastContainer />
          <div className="d-flex align-items-start gap-3 mb-2">
            <i
              className="ri-arrow-left-line fs-3 cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            <h2>{t("reserve2")} № {client?.result?.id}</h2>
          </div>
         <Row className="justify-content-around">
         <Col xs="12" lg="5">
         <Card style={{ borderRadius: "1rem" }}>
            {data?.result?.items?.map((item, i) => (
              <CardBody
                key={i}
                className="d-flex align-items-center justify-content-between"
              >
               <div
                      
                        className="d-flex gap-5 justify-content-between"
                      >
                        <div className="d-flex gap-3 align-items-start">
                          <img
                            style={{
                              maxWidth: "100px",
                              width: "100px",
                              maxHeight: "100px",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                            src={item.variation?.files?.[0]?.url}
                            alt={item.variation?.files?.[0]?.id}
                          />
                          <div className="d-flex flex-column gap-1">
                            <div style={{ width: "250px" }}>
                              {item?.variation?.product?.name}
                            </div>
                            <strong>
                              {prettify(
                                item?.variation?.prices?.find(
                                  (price) => price.type === "Price"
                                )?.value
                              )}
                            </strong>

                            <div>x{item?.count}</div>
                          </div>
                        </div>
                      </div>
              </CardBody>
            ))}
          </Card>
         </Col>
          <Col xs="12" lg="5" className="mb-3">
              <Card
                style={{
                
                  borderRadius: "1rem",
                }}
              >
                <CardHeader
                  style={{
                    borderTopLeftRadius: "1rem",
                    borderTopRightRadius: "1rem",
                  }}
                >
                  <h3>{t("order_information")}</h3>
                </CardHeader>
                <CardBody>
                  <Textfield name={"SubOrdersID"} source={data?.result?.id} />
                  <Textfield
                    name={"phone_number"}
                    source={"+" + client?.result?.phoneNumber}
                  />
                  <Textfield
                    name={"client_full_name"}
                    source={client?.result?.fullName}
                  />
                  <div hidden={client?.result?.deliveryType === 1}>
                    <Textfield name={"Address"} source={client?.result?.address} />
                    <Textfield name={"Region"} source={client?.result?.regionName} />
                    <Textfield name={"District"} source={client?.result?.destrictName} />
                  </div>
                  <Textfield
                    name={"total_price"}
                    source={prettify(totalPrice)}
                  />
                  <Textfield
                    name={"delivery_status"}
                    source={
                      client?.result?.deliveryType === 0 ? t("delivery") : t("pickup")
                    }
                  />
                  <Textfield
                    name={"created_date"}
                    source={moment(client?.result?.createdDate).format("DD.MM.YYYY")}
                  />
                  <Textfield name={"Comments"} source={client?.result?.comment} />
                </CardBody>
              </Card>
<Card style={{borderRadius: "1rem"}}>
<CardBody>

          <Form onSubmit={onSubmit}>
            <div className="mb-3 w-100">
              <Label for="positionId">{t("status")}</Label>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={[
                      { value: 0, label: t("reserve_created") },
                      { value: 1, label: t("reserve_undrway") },
                      { value: 2, label: t("reserve_sent") },
                      { value: 3, label: t("reserve_complated") },
                      { value: 4, label: t("reserve_cancelled") },
                      { value: 5, label: t("reserve_refund") },
                    ]}
                    placeholder="Select Position"
                  />
                )}
              />
          
            </div>
            <div className="mb-3 w-100">
              <Input
                name="reason"
                control={control}
                errors={errors}
                label={t("reason")}
                inputProps={{
                  placeholder: t("reason"),
                  type: "textarea"
                }}
              />
              
            </div>
         <div className="d-flex justify-content-end">
            <Button
              className="btn btn-primary d-flex align-items-center gap-2  "
              type="submit"
            >
              {loading ? (
                <Spinner size="sm" className="me-2">
                  Loading...
                </Spinner>
              ) : null}
              {t("Save")}
              <i className="ri-check-line"></i>
            </Button>
         </div>
          </Form>
</CardBody>
</Card>
            </Col>
         </Row>
        </div>
      )}
    </div>
  );
};

export default ReserveSingle;
