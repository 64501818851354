import React, { useEffect } from "react";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Spinner,
  Input,
  InputGroup,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import TableContainer from "../../../Components/Common/TableContainer";
import { getProductsQuery } from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";
import Visibility from "../Components/Visibility";
import StateSelect from "../Components/StateSelect";
import { prettify } from "../ui/prettify";
import FilterInputs from "../ui/filter-inputs";
import PageSize from "../ui/pageSize";

const values = {
  page: 1,
  size: 20,
  moderationStatus: 1,
  productName: null,
};

const Products = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(values);
  const [check, setCheck] = useState(false);
  const { data, isFetched } = useQuery({
    ...getProductsQuery(formValues),
  });

  const columns = useMemo(() => [
    {
      Header: t("Product name"),
      accessor: "name",
      filterable: false,
      Cell: ({ row }) => {
        return (
          <Link
            to={`/products/${row.original.id}`}
            className="fw-medium link-primary"
          >
            {row.original.name}
          </Link>
        );
      },
    },
    {
      Header: t("Product category"),
      accessor: (row) => row?.category?.name,
      filterable: false,
    },
    {
      Header: t("Price"),
      accessor: (row) =>
        row?.variations && row.variations[0]
          ? prettify(row.variations[0].originPrice)
          : "",
      filterable: false,
    },

    {
      Header: t("Visible"),
      accessor: "isVisible",
      filterable: false,
      Cell: ({ row }) => {
        const { id, isVisible, ...data } = row.original;
        return !check ? (
          row.original.isVisible === false ? (
            <span className="badge text-uppercase bg-danger ">
              {t("Not visible")}
            </span>
          ) : (
            <span className="badge text-uppercase bg-success">
              {t("Visible")}
            </span>
          )
        ) : (
          <Visibility id={id} data={{ isVisible }} disabled={!check} />
        );
      },
    },

    {
      Header: t("Status"),
      accessor: "state",
      filterable: false,
      Cell: ({ row }) => {
        const { id, state, ...data } = row.original;
        return <StateSelect id={id} data={{ state }} disabled={!check} />;
      },
    },

    {
      Header: t("Actions"),
      accessor: "id",
      Cell: ({ value }) => {
        return (
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item">
              <Button
                color="link"
                className="display-4 text-black"
                onClick={() => window.open(`/products/edit/${value}`, "_blank")}
              >
                <i className="ri-pencil-fill"></i>
              </Button>
            </li>
          </ul>
        );
      },
    },
  ]);

  const handleChange = (e) => {
    const newChecked = e.target.checked;
    setCheck(newChecked);
  };
  const handlePageChange = (page) => {
    setFormValues((prevState) => ({ ...prevState, page }));
  };
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h2>{t("Products")}</h2>
              <div className="d-flex align-items-center gap-3 w-100 justify-content-end">
                <div>
                  <FilterInputs
                    setFormValues={setFormValues}
                    names={"productName"}
                    placeholder={"Product name"}
                  />
                </div>
                <div className="d-flex align-items-center gap-2">
                  <span>{t("action_check")}</span>
                  <div className="form-check form-switch mt-1">
                    <Input
                      type="checkbox"
                      role="switch"
                      className="form-check-input"
                      onChange={handleChange}
                      checked={check}
                    />
                  </div>
                </div>
                <Button
                  onClick={() => navigate("/products/add")}
                  className="d-flex gap-1 align-items-center"
                >
                  {t("Add product")}
                  <i className=" ri-add-line fs-4"></i>
                </Button>
              </div>
            </div>
            <Card id="orderList">
              <CardBody className="pt-0">
                <div>
                  {isFetched ? (
                    <>
                      <TableContainer
                        pagination={{
                          currentPage: formValues?.page,
                          totalPages: data?.pagination?.TotalPages,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        data={data?.item}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        isGlobalSearch={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      >
                        {data?.item?.length === 0 && (
                          <div className="text-center">{t("No data")}</div>
                        )}
                      </TableContainer>

                      <PageSize
                        formValues={formValues}
                        setFormValues={setFormValues}
                        tableKey={"products"}
                      />
                    </>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <Spinner />
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Products;
