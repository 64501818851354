import React, { useEffect } from "react";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
  Input,
} from "reactstrap";
import * as moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import classnames from "classnames";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import TableContainer from "../../../Components/Common/TableContainer";
import { getModerationsQuery } from "../../../queries/index";
import Loader from "../../../Components/Common/Loader";
import "react-toastify/dist/ReactToastify.css";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { prettify } from "../ui/prettify";
const schema = createSchema({});
const values = {
  page: 1,
  size: 20,
};

const ProductsModerate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { watch, setValue } = useHookForm(values, schema);
  const tableKey = "productsmoderate";
  const { data, isFetched } = useQuery({
    ...getModerationsQuery({
      ...watch(),
    }),
  });
  useEffect(() => {
    const storedPageSize = JSON.parse(localStorage.getItem("pageSizes")) || {};
    const pageSize = storedPageSize[tableKey] || watch("size");
    setValue("size", pageSize);
  }, [setValue]);
  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setValue("size", newSize);
    setValue("page", 1);
    const storedPageSizes = JSON.parse(localStorage.getItem("pageSizes")) || {};
    storedPageSizes[tableKey] = newSize;
    localStorage.setItem("pageSizes", JSON.stringify(storedPageSizes));
  };

  const columns = useMemo(
    () => [
      {
        Header: t("Product name"),
        accessor: "name",
        filterable: false,
        Cell: ({ row }) => (
          <Link to={`/products-moderate/${row.original.variations[0].id}`}>
            {row.original.name}
          </Link>
        ),
      },
      {
        Header: t("Product category"),
        accessor: (row) => row.category?.name,
        filterable: false,
      },
      {
        Header: t("Price"),
        accessor: (row) => prettify(row.variations[0].originPrice),
        filterable: false,
      },

      {
        Header: t("Visible"),
        accessor: "isVisible",
        filterable: false,
        Cell: ({ value }) => {
          return value === false ? (
            <span className="badge text-uppercase bg-danger ">
              {t("Not visible")}
            </span>
          ) : (
            <span className="badge text-uppercase bg-success">
              {t("Visible")}
            </span>
          );
        },
      },

      {
        Header: t("status"),
        accessor: "state",
        Cell: ({ value }) => {
          switch (value) {
            case 1:
              return (
                <span className="badge text-uppercase bg-success ">
                  {t("Active")}
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase  bg-danger">
                  {t("Not active")}
                </span>
              );
          }
        },
      },
    ],
    []
  );

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h2>{t("Products")}</h2>
              <Button
                onClick={() => navigate("/products/add")}
                className="d-flex gap-1 align-items-center"
              >
                {t("Add product")}
                <i className=" ri-add-line fs-4"></i>
              </Button>
            </div>
            <Card id="orderList">
              <CardBody className="pt-0">
                <div>
                  {isFetched ? (
                    <div>
                      <TableContainer
                        pagination={{
                          currentPage: watch("page"),
                          totalPages: data?.pagination?.TotalPages,
                          onChange: (page) => setValue("page", page),
                        }}
                        columns={columns}
                        data={data || []}
                        isGlobalFilter={false}
                        isGlobalSearch={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      >
                        {data?.length === 0 && (
                          <div className="text-center ">{t("No data")}</div>
                        )}
                      </TableContainer>

                      <Input
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={watch("size")}
                        onChange={handlePageSizeChange}
                        className="w-auto"
                      >
                        <option value={20}>{t("20")}</option>
                        <option value={50}>{t("50")}</option>
                        <option value={100}>{t("100")}</option>
                      </Input>
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductsModerate;
