import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, InputGroup } from "reactstrap";

const FilterInputs = ({ setFormValues, names, placeholder }) => {
  const { t } = useTranslation();
  const [filterValues, setFilterValues] = useState({
    [names]: "",
  });
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearch = (e) => {
    e.preventDefault();

    setFormValues((prevState) => ({
      ...prevState,
      [names]: filterValues[names].trim(),
      page: 1,
    }));
  };
  
  return (
    <Form>
      <InputGroup>
        <Input
          type="text"
          name={names}
          className="form-control"
          value={filterValues[names]}
          onChange={handleFilterChange}
          placeholder={t(placeholder)}
        />
        <Button type="submit" onClick={handleSearch}>
          <i className="bx bx-search"></i>
        </Button>
      </InputGroup>
    </Form>
  );
};

export default FilterInputs;
