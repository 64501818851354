import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import Loader from "../../../Components/Common/Loader";
import { useQuery } from "@tanstack/react-query";
import classnames from "classnames";
import ReserveTable from "../ui/reserve-table";
import { toast, ToastContainer } from "react-toastify";
import CustomModal from "../../../Components/Common/Modal";
import { getOrders2Query } from "../../../queries";
const values = {
  page: 1,
  size: 20,
  phoneNumber: null,
  name: null,
  subOrderState: ""
};

const Orders2 = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(values);
  const [open, setOpen] = useState(false);
  const [filterValues, setFilterValues] = useState({
    phoneNumber: "",
    name: "",
  });
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const { data, isFetched } = useQuery({
    ...getOrders2Query(formValues),
    onError: (err) => {
      if (err.response && err.response.status === 500) {
        toast.error(
          t("An error occurred on the server. Please try again later.")
        );
      }
    },
  });
  
  const toggleModal = () => {
    setOpen((prev) => !prev);
  };
  const handleNavClick = (value) => {
    setFormValues((prevState) => ({ ...prevState, subOrderState: value }));
  };

  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value);
    setFormValues((prevState) => ({
      ...prevState,
      size: newSize,
      page: 1,
    }));
  };

  const handlePageChange = (page) => {
    setFormValues((prevState) => ({ ...prevState, page }));
  };

  

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "phoneNumber") {
      setFormValues((prevState) => ({
        ...prevState,
        phoneNumber: value,
        page: 1,
      }));
    } else {
      setFilterValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  

  const handleSubmitFilter = (e) => {
    e.preventDefault();

    setFormValues((prevState) => ({
      ...prevState,
      
      name: filterValues.name,
      page: 1,
    }));
    setIsFilterApplied(true)
    toggleModal();
  };

  const handleReset = () => {
    setFilterValues({
      name: "",
    });
    setFormValues((prevState) => ({
      ...prevState,
    
      name: null,
      page: 1,
    }));
    setIsFilterApplied(false);
  };
  

  useEffect(() => {
    const hasFilters = filterValues.name || filterValues.phoneNumber;
    setIsFilterApplied(!!hasFilters); 
  }, [formValues]);

  return (
    <div className="page-content">
      <ToastContainer position="top-center" />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("reserve")}</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
           
                     {["", 0, 1, 2, 3, 4, 5].map((state, index) => (
                        <NavItem key={state}>
                          <NavLink
                            className={classnames(
                              { active: formValues?.subOrderState === state },
                              "fw-semibold"
                            )}
                            onClick={() => handleNavClick(state)}
                            href="#"
                          >
                            {t(
                              [
                                "all_reserve",
                                "reserve_created",
                                "reserve_undrway",
                                "reserve_sent",
                                "reserve_complated",
                                "reserve_cancelled",
                                "reserve_refund",
                              ][index]
                            )}
                          </NavLink>
                        </NavItem>
                      ))}
                  </Nav>
                  <div className="d-flex align-items-center gap-2">
                 <Input
                   type="number"
                   name="phoneNumber"
                   value={formValues.phoneNumber}
                   onChange={handleFilterChange}
                   placeholder={t("phone")}
                 />
                   <Button
                  onClick={toggleModal}
                  color={isFilterApplied ? "primary": "light"}
                  className="d-flex align-items-center gap-1"
                  >
                  <i className="ri-equalizer-fill"></i>
                  {t("Filter")}
                </Button>
                  </div>
                  </div>
                  {isFetched ? (
                    <div className="mt-2">
                      <ReserveTable
                        data={data?.result?.items}
                        pagination={{
                          currentPage: formValues?.page,
                          totalPages: data?.pagination?.TotalPages,
                          onChange: handlePageChange,
                        }}
                      />

                      <Input
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={formValues?.size}
                        onChange={handlePageSizeChange}
                        className="w-auto"
                      >
                         {[20, 50, 100].map((size) => (
                          <option key={size} value={size}>
                            {t(String(size))}
                          </option>
                        ))}
                      </Input>
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <CustomModal
            isOpen={open}
            toggleModal={toggleModal}
            title={t("Filter")}
            content={
              <Form onSubmit={handleSubmitFilter} className="d-flex flex-column gap-3">
              
                <div>
                <Label>{t("client_full_name")}</Label>
                 <Input
                   type="text"
                   name="name"
                   value={filterValues.name}
                   onChange={handleFilterChange}
                   placeholder={t("client_full_name")}
                 />
                </div>
                 <div className="d-flex justify-content-end gap-3 mb-0">
                 <Button onClick={handleReset} color="light" type="submit">{t("reset")}</Button>
                 <Button disabled={!filterValues.name && !filterValues.phoneNumber} type="submit">{t("Save")}</Button>
                 </div>
               </Form>
            }
          />
           
         
        </Row>
      </Container>
    </div>
  );
};

export default Orders2;
