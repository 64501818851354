import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  Spinner,
  Label,
} from "reactstrap";
import { Input } from "../../Components/atoms/input";
import { Input as BootstrapInput } from "reactstrap";
import { login } from "../../api/auth";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import useHookForm from "../../hooks/useHookForm";
import createSchema from "../../helpers/createSchema";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import InputMasked from "../../Components/atoms/inputMask";
import removeChars from "../../Components/atoms/removeChars";
const schema = createSchema({
  phoneNumber: "phone",
  password: "auth_password",
});
const values = {
  phoneNumber: "998",
  password: "",
  isTrusted: true,
  deviceId: uuidv4(),
  deviceType: 3,
  displayName: navigator.userAgent,
  userType: 3,
  otp: "",
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [check, setCheck] = useState(true);
  const [change, setChange] = useState(true);
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const clients = useQueryClient();
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useHookForm(values, schema);

  const onChange = (event) => {
    const checked = event.target.checked;
    setCheck(checked);
    setValue("isTrusted", checked);
  };

  const onSubmit = handleSubmit(async (res) => {
    setLoading(true);
    setPhone(res.phoneNumber);
    const loginData = {
      ...res,
      phoneNumber: removeChars(res.phoneNumber),
      deviceId: values.deviceId,
      deviceType: values.deviceType,
      displayName: values.displayName,
      userType: values.userType,
    };
    try {
      const res = await login(loginData, "POST");
      toast(res.result.sentText, { type: "success", theme: "colored" });
      setChange(false);
      clients.invalidateQueries({ queryKey: ["profile"] });
    } catch (error) {
      toast(error.data?.error.errorMessage, {
        type: "error",
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  });

  const onSubmitOtp = handleSubmit(async (res) => {
    setLoading2(true);
    const loginData = {
      // ...res,
      phoneNumber: removeChars(res.phoneNumber),
      otp: removeChars(res.otp),
      deviceId: values.deviceId,
      deviceType: values.deviceType,
      displayName: values.displayName,
      userType: values.userType,
    };
    try {
      const response = await login(loginData, "PUT");
      localStorage.setItem("token", response.result.token);
      localStorage.setItem("access", JSON.stringify(response.result.access))
      navigate("/");
      clients.invalidateQueries({ queryKey: ["profile"] });
      clients.invalidateQueries({ queryKey: ["profileInfo"] });
    } catch (error) {
      toast(error.data?.error.errorMessage, {
        type: "error",
        theme: "colored",
      });
    } finally {
      setLoading2(false);
    }
  });

  document.title = "Авторизация | Taqsim Admin";

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <ToastContainer position="top-center" />
        <Container>
          <Row className="justify-content-center mt-5">
            <Col md={8} lg={6} xl={5} className="mt-5">
              <Card className="mt-5">
                <CardBody className="p-4">
                  <div className="text-center mt-2 ">
                    <h5 style={{ fontSize: "18px", fontWeight: 580 }}>
                      {t("Partner's office")}
                    </h5>
                    <div className="d-flex flex-column gap-1">
                      <span style={{ fontSize: "23px", fontWeight: 550 }}>
                        {!change ? phone : null}
                      </span>
                      <span>
                        {!change
                          ? t(
                              "An SMS with a code has been sent to the specified number"
                            )
                          : null}
                      </span>
                    </div>
                  </div>
                  <div className="p-2 mt-4">
                    {change ? (
                      <Form onSubmit={onSubmit}>
                        <div className="mb-3">
                          <InputMasked
                            name={"phoneNumber"}
                            control={control}
                            errors={errors}
                            maskProps={{ mask: "\\+\\9\\9\\8 99 999 99 99" }}
                          />
                        </div>
                        <div className="mb-3">
                          <Input
                            name="password"
                            control={control}
                            errors={errors}
                            label="password"
                            inputProps={{
                              type: "password",
                              placeholder: t("Password"),
                            }}
                          />
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                          <div className="form-check">
                            <BootstrapInput
                              className="form-check-input"
                              type="checkbox"
                              id="formCheck2"
                              name="isTrusted"
                              onChange={onChange}
                              checked={check}
                            />{" "}
                            <Label
                              className="form-check-label"
                              for="formCheck2"
                            >
                              {t("Trust this device")}
                            </Label>
                          </div>

                          <Link to={"/forgot-password"}>
                            {t("Forgot your password")}
                          </Link>
                        </div>

                        <div className="mt-4 d-flex justify-content-center">
                          <Button
                           
                            className="btn btn-primary w-50"
                            type="submit"
                          >
                            {loading ? (
                              <div className="d-flex align-items-center gap-1 justify-content-center">
                                <Spinner size={"sm"} />
                                <div>{t("sign_in")}</div>
                              </div>
                            ) : (
                              <div>{t("sign_in")}</div>
                            )}
                          </Button>
                        </div>
                        <div
                          className="d-flex align-items-center gap-2 justify-content-center mt-2 "
                          style={{ fontSize: "14px" }}
                        >
                          <span>{t("Don't have an account?")}</span>
                          <Link to={"/register"}>{t("Registration")}</Link>
                        </div>
                      </Form>
                    ) : (
                      <Form onSubmit={onSubmitOtp}>
                        <div className="mb-3">
                          {/* <Input
                            name="otp"
                            control={control}
                            errors={errors}
                            inputProps={{
                              type: "text",
                              placeholder: t("Enter confirmation code"),
                            }}
                          /> */}
                           <InputMasked
                          name="otp"
                          control={control}
                          errors={errors}
                          maskProps={{ mask: "999 999" }}
                          inputProps={{
                            
                            autoComplete: "off",
                            placeholder: t("Enter confirmation code"),
                          }}
                        />
                        </div>
                        <div className="d-flex justify-content-center ">
                          <Button className="w-50 btn btn-primary">
                            {loading2 ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            ) : null}
                            {t("sign_in")}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
    // {/* </ToastContainer> */}
  );
};

export default Login;
