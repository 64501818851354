import React, { useState, useEffect } from "react";
import { ProductsVisibility } from "../../../api";
import { useQueryClient } from "@tanstack/react-query";
import { Input, Spinner } from "reactstrap";
import { toast } from "react-toastify";

const Visibility = ({ id, data, disabled }) => {
  const clients = useQueryClient();
  const [isChecked, setIsChecked] = useState(data?.result?.isVisible || data?.isVisible);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setIsChecked(data?.result?.isVisible || data?.isVisible);
  }, [data]);

  const visibility = async (check) => {
    setLoading(true)
    try {
      await ProductsVisibility(id, { visibility: check });
      clients.invalidateQueries({ queryKey: ["products-id", id] });
      clients.invalidateQueries({ queryKey: ["products"] });
      clients.invalidateQueries({ queryKey: ["productsEdit", id] });
    } catch (error) {
      toast(error.data?.error?.errorMessage, {
        theme: "colored",
        type: "error",
      });
    }finally {
      setLoading(false)
    }
  };

  const handleChange = (e) => {
    const newChecked = e.target.checked;
    setIsChecked(newChecked);
    visibility(newChecked);
  };

  return (
    <div>
      {
        loading ? (
          <Spinner size={"sm"} />
        ) : (
          <div className="form-check form-switch">
        <Input
          type="checkbox"
          role="switch"
          className="form-check-input"
          onChange={handleChange}
          checked={isChecked}
          disabled={disabled}
        />
      </div>
        )
      }
      
    </div>
  );
};

export default Visibility;
