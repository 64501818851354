import React from "react";
import { useTranslation } from "react-i18next";
import {
  Col,
  Form,
  Label,
  Row,
  Input as ReactInput,
  Button,
  Spinner,
} from "reactstrap";
import { Input } from "../../../../Components/atoms/input";
import { Controller } from "react-hook-form";
import Select from "react-select";
import InputMasked from "../../../../Components/atoms/inputMask";
import { formatAccountNumber, unformatAccountNumber } from "../../ui/prettify";

const OrganizationEditForm = ({
  data,
  control,
  setValue,
  Submit,
  errors,
  regions,
  referenceFormal,
  loading,
  GetId,
  saleTypeState,
  districts,
  DistrictChange,
  setImageFile,
  handleSaleTypeChange,
  regionLoading,
  RegionChange,
  districtsLoading,
  selectedServiceRegions,
  handleServiceRegionChange,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {" "}
      <Form onSubmit={Submit} className="d-flex flex-column gap-3">
        <h4 style={{ fontWeight: 600 }}>Основные данные</h4>
        <Row>
          <Col md="6">
            <Label style={{ fontWeight: 550 }}>
              {t("Legal name of the organization")} <span className="text-danger">*</span>
            </Label>
            <Input
              control={control}
              errors={errors}
              inputProps={{
                placeholder: t("Enter the title"),
              }}
              name="organizationName"
            />
          </Col>
          <Col md="6">
            <Label style={{ fontWeight: 550 }}>
              {t("Type of organization")} <span className="text-danger">*</span>
            </Label>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={referenceFormal?.result?.items?.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  placeholder="Select Position"
                  value={field.value}
                  onChange={(selectedOption) =>
                    setValue("type", selectedOption)
                  }
                />
              )}
            />
            {errors.type &&  (
                        <span className="text-danger">
                          {t(errors.type.message)}
                        </span>
                      )}
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Label style={{ fontWeight: 550 }}>
              {t("Full name of the manager")} <span className="text-danger">*</span>
            </Label>
            <Input
              control={control}
              errors={errors}
              name="ownerFullName"
              inputProps={{
                placeholder: t("Enter your full name"),
              }}
            />
          </Col>
          <Col md="6">
            <Label style={{ fontWeight: 550 }}>{t("ИНН юр. лица")} <span className="text-danger">*</span></Label>

            <InputMasked
              name="organizationTin"
              control={control}
              errors={errors}
              maskProps={{ mask: "999 999 999" }}
              className="custom-class"
              inputProps={{
                placeholder: t("Enter TIP"),
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Label style={{ fontWeight: 550 }}>{t("VAT payer number")} <span className="text-danger">*</span></Label>

            <InputMasked
              name="vatNumber"
              control={control}
              errors={errors}
              maskProps={{ mask: "9999 9999 9999" }}
              inputProps={{
                placeholder: t("Enter number (12 digits)"),
              }}
              className="custom-class"
            />
          </Col>
          <Col md="6" className="d-flex align-items-center">
            <div>
              <Label style={{ fontWeight: 550 }}>
                {t("Certificate of registration of the organization")}
              </Label>
              <ReactInput
                className="form-control form-control-md"
                id="formSizeSmall"
                type="file"
                name="files"
                onChange={(e) => setImageFile(e.target.files[0])}
              />
              {data?.result?.organizationFiles?.find(
                (item) => item.type === 1
              ) && (
                <p>
                  {
                    data.result.organizationFiles.find(
                      (item) => item.type === 1
                    ).id
                  }
                </p>
              )}
            </div>

            <Button size="md" color="link" onClick={GetId}>
              Download
            </Button>
          </Col>
          <Col md="6">
          <Label style={{ fontWeight: 550 }}>{t("coordinate_lat")} <span className="text-danger">*</span></Label>
            <Input
              errors={errors}
              control={control}
              name="latitude"
              inputProps={{
                type: "number"
              }}
            />
          </Col>
          <Col md="6">
          <Label style={{ fontWeight: 550 }}>{t("coordinate_long")} <span className="text-danger">*</span></Label>
            <Input
              errors={errors}
              control={control}
              name="longitude"
              inputProps={{
                
                type: "number"
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Label style={{ fontWeight: 550 }}>{t("Sale type")}</Label>
            <div className="d-flex flex-column">
            <div className="form-check">
                <ReactInput
                  type="checkbox"
                  id="installment"
                  value={1}
                  onChange={() => handleSaleTypeChange(1)}
                  checked={saleTypeState.includes(1)}
                />
                <Label htmlFor="installment">{t("Installment")}</Label>
              </div>
              <div className="form-check">
                <ReactInput
                  type="checkbox"
                  id="compensation"
                  value={2}
                  onChange={() => handleSaleTypeChange(2)}
                  checked={saleTypeState.includes(2)}
                />
                <Label htmlFor="compensation">{t("Compensation")}</Label>
              </div>

              <div className="form-check">
                <ReactInput
                  type="checkbox"
                  id="paid"
                  value={4}
                  onChange={() => handleSaleTypeChange(4)}
                  checked={saleTypeState.includes(4)}
                />
                <Label htmlFor="paid">{t("Sale")}</Label>
              </div>
              <div className="form-check">
                <ReactInput
                  type="checkbox"
                  id="bmt"
                  value={8}
                  onChange={() => handleSaleTypeChange(8)}
                  checked={saleTypeState.includes(8)}
                />
                <Label htmlFor="bmt">BMT</Label>
              </div>
            </div>
            {errors.productSaleTypes && errors &&  (
                        <span className="text-danger">
                          {t(errors.productSaleTypes.message)}
                        </span>
                      )}
          </Col>
        </Row>
        <h4 style={{ fontWeight: 600 }}>{t("Address")}</h4>
        <Row>
          <Col md="6">
            <Label style={{ fontWeight: 550 }}>{t("City")} <span className="text-danger">*</span></Label>
            <Controller
              control={control}
              name="regionId"
              render={({ field }) => (
                <Select
                  {...field}
                  options={regions?.result?.regions?.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  isLoading={regionLoading}
                  onChange={RegionChange}
                  value={field.value}
                />
              )}
            />
              {errors.regionId &&  (
                        <span className="text-danger">
                          {t(errors.regionId.message)}
                        </span>
                      )}
          </Col>
          <Col md="6">
            <Label style={{ fontWeight: 550 }}>{t("District")} <span className="text-danger">*</span></Label>
            <Controller
              control={control}
              name="districtId"
              render={({ field }) => (
                <Select
                  {...field}
                  options={districts?.result?.districts?.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  isLoading={districtsLoading}
                  onChange={DistrictChange}
                  value={field.value}
                />
              )}
            />
             {errors.districtId &&  (
                        <span className="text-danger">
                          {t(errors.districtId.message)}
                        </span>
                      )}
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Label style={{ fontWeight: 550 }}>{t("Address")} <span className="text-danger">*</span></Label>
            <Controller
              control={control}
              name="address"
              render={({ field }) => (
                <textarea
                  {...field}
                  className="form-control"
                  rows={5}
                  value={field.value}
                  placeholder={t("Enter the title")}
                />
              )}
            />
            {errors.address &&  (
                        <span className="text-danger">
                          {t(errors.address.message)}
                        </span>
                      )}
          </Col>
        </Row>

        <Row>
          <Label style={{ fontWeight: 550 }}>
            {t("The presence of branches and agents in the regions")} <span className="text-danger">*</span>
          </Label>
          {regions?.result?.regions?.map((region, index) => (
            <Col xs="6" md="3" key={region.id}>
              <div className="d-flex gap-2 align-items-start form-check form-check-secondary">
                <input
                  type="checkbox"
                  className="form-check-input form-check-secondary "
                  checked={selectedServiceRegions.includes(region.id)}
                  id={region.name}
                  onChange={() => handleServiceRegionChange(region.id)}
                />
                <Label htmlFor={region.name}>{region.name}</Label>
              </div>
             
            </Col>
          ))}
           {errors.serviceRegionsIds && errors &&  (
                        <span className="text-danger">
                          {t(errors.serviceRegionsIds.message)}
                        </span>
                      )}
        </Row>
        <h4 style={{ fontWeight: 600 }}>{t("Bank details")}</h4>
        <Row>
          <Col md="6">
            <Label style={{ fontWeight: 550 }}>{t("Servicing bank")} <span className="text-danger">*</span></Label>
            <Input
              errors={errors}
              control={control}
              name="servingBankName"
              inputProps={{
                placeholder: t("Enter the title"),
              }}
            />
          </Col>
          <Col md="6">
            <Label style={{ fontWeight: 550 }}>{t("МФО банка")} <span className="text-danger">*</span></Label>
            <InputMasked
              errors={errors}
              control={control}
              name="mfo"
              maskProps={{ mask: "99999" }}
              inputProps={{
                placeholder: t("Enter MFO 5 (code)"),
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Label style={{ fontWeight: 550 }}>{t("Payment account")} <span className="text-danger">*</span></Label>
              <InputMasked
              errors={errors}
              control={control}
              name="accountNumber"
              
              maskProps={{ mask: "9999 9999 9999 9999 9999" }}
              inputProps={{
                placeholder: t("Enter Current Account 20 (cipher)"),
              }}
            />
          </Col>
          <Col md="6">
            <Label style={{ fontWeight: 550 }}>{t("ОКЭД")} <span className="text-danger">*</span></Label>
            <InputMasked
              control={control}
              errors={errors}
              name="oked"
              maskProps={{ mask: "99999" }}
            />
          </Col>
        </Row>
        <h4 style={{ fontWeight: 600 }}>{t("Additional information")}</h4>
        <Row>
          <Col md="6">
            <Label style={{ fontWeight: 550 }}>E-mail <span className="text-danger">*</span></Label>
            <Input
              errors={errors}
              name="email"
              inputProps={{
                placeholder: t("Enter Email"),
              }}
              control={control}
            />
          </Col>
          <Col md="6">
            <Label style={{ fontWeight: 550 }}>{t("Contact number")} <span className="text-danger">*</span></Label>

            <InputMasked
              errors={errors}
              name={"phone"}
              control={control}
              maskProps={{ mask: "\\9\\9\\8 99 999 99 99" }}
            />
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Label style={{ fontWeight: 550 }}>{t("Description")}</Label>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <textarea
                  {...field}
                  className="form-control"
                  rows={5}
                  value={field.value}
                  placeholder={t("Enter a description")}
                />
              )}
            />
          </Col>
        </Row>

        <div className="d-flex align-items-start gap-3 mt-4">
          <Button
            type="submit"
            className="btn btn-primary btn-label right ms-auto nexttab"
          >
            {loading ? (
              <div className="d-flex align-items-center gap-3">
                <Spinner size={"sm"} />
                <div>
                  <i className="ri-check-line label-icon align-middle fs-16 ms-2"></i>
                  {t("Save")}
                </div>
              </div>
            ) : (
              <div>
                <i className="ri-check-line label-icon align-middle fs-16 ms-2"></i>
                {t("Save")}
              </div>
            )}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default OrganizationEditForm;
