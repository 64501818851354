import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Input as ReactInput,
  Label,
  Form,
  Button,
  Spinner,
} from "reactstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Link, useNavigate } from "react-router-dom";

//import images
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Controller } from "react-hook-form";
import useHookForm from "../../hooks/useHookForm";
import InputMasked from "../../Components/atoms/inputMask";
import { v4 as uuidv4 } from "uuid";
import { t } from "i18next";
import createSchema from "../../helpers/createSchema";
import removeChars from "../../Components/atoms/removeChars";
import { register } from "../../api/auth";
import { Input } from "../../Components/atoms/input";
import CustomModal from "../../Components/Common/Modal";
const schema = createSchema({
  phoneNumber: "phone",
  password: "auth_password",
  confirmPassword: t("retypePassword2"),
  isTrusted: "isTrusted",
});
const values = {
  phoneNumber: "998",
  password: "",
  confirmPassword: "",
  isTrusted: false,
  deviceId: uuidv4(),
  deviceType: 3,
  displayName: navigator.userAgent,
  userType: 3,
  otp: "",
};
const Register = () => {
  const [change, setChange] = useState(true);
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [phone, setPhone] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useHookForm(values, schema);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const onChange = () => {
    setCheck(true);
    setValue("isTrusted", true);
  };

  const handleOnConfirm = (event) => {
    const checked = event.target.checked;
    setIsModalOpen(false);
    setCheck(checked);
    setValue("isTrusted", true);
  };

  const Submit = async (data) => {
    // if (!check) {
    //   toast(t("You must agree to the terms of use"), {
    //     type: "error",
    //     theme: "colored",
    //   });
    //   return;
    // }
    setLoading(true);
    setPhone(data.phoneNumber);
    const registerData = {
      ...data,
      phoneNumber: removeChars(data.phoneNumber),
      deviceId: values.deviceId,
      deviceType: values.deviceType,
      displayName: values.displayName,
      userType: values.userType,
    };
    try {
      const res = await register(registerData, "POST");
      toast(res.result.sentText, { type: "success", theme: "colored" });
      setChange(false);
    } catch (error) {
      toast(error.data?.error.errorMessage, {
        type: "error",
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  const SubmitOtp = handleSubmit(async (res) => {
    setLoadingOtp(true);
    const registerData = {
      ...res,
      otp: removeChars(res.otp),
      phoneNumber: removeChars(res.phoneNumber),
      deviceId: values.deviceId,
      deviceType: values.deviceType,
      displayName: values.displayName,
      userType: values.userType,
    };
    try {
      const response = await register(registerData, "PUT");
      localStorage.setItem("token", response.result.token);
      navigate("/organization/create");
    } catch (error) {
      toast(error.data?.error.errorMessage, {
        type: "error",
        theme: "colored",
      });
    } finally {
      setLoadingOtp(false);
    }
  });
  document.title = "Basic SignUp | Velzon - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <ToastContainer position="top-center" />
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="20" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">
                    Premium Admin & Dashboard Template
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2 ">
                      <h5 style={{ fontSize: "18px", fontWeight: 580 }}>
                        {t("Registration")}
                      </h5>
                      <div className="d-flex flex-column gap-1">
                        <span style={{ fontSize: "23px", fontWeight: 550 }}>
                          {!change ? phone : null}
                        </span>
                        <span>
                          {!change
                            ? t(
                                "An SMS with a code has been sent to the specified number"
                              )
                            : null}
                        </span>
                      </div>
                    </div>

                    <div className="p-2 mt-4 ">
                      {change ? (
                        <Form
                          onSubmit={handleSubmit(Submit)}
                          className="needs-validation d-flex flex-column gap-3"
                        >
                          <div>
                            <Label>{t("Phone number")}</Label>
                            <InputMasked
                              name={"phoneNumber"}
                              control={control}
                              errors={errors}
                              maskProps={{ mask: "\\+\\9\\9\\8 99 999 99 99" }}
                            />
                          </div>
                          <div>
                            <Label>{t("Password")}</Label>
                            <Input
                              name="password"
                              control={control}
                              errors={errors}
                              inputProps={{
                                type: "password",
                                placeholder: t("Password"),
                              }}
                            />
                          </div>
                          <div>
                            <Label>{t("Confirm password")}</Label>
                            <Input
                              name="confirmPassword"
                              control={control}
                              errors={errors}
                              inputProps={{
                                type: "password",
                                placeholder: t("Confirm password"),
                              }}
                            />
                          </div>

                          <div className="d-flex justify-content-center align-items-center flex-column gap-1">
                            <span style={{ color: "gray" }}>
                              {t("I agree and accept")}
                            </span>
                            <div
                              className="d-flex gap-1"
                              style={{ fontSize: "14px" }}
                            >
                              <Controller
                                name="isTrusted"
                                control={control}
                                render={({ field }) => (
                                  <ReactInput
                                    {...field}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="check"
                                    onChange={toggleModal}
                                    checked={check}
                                    invalid={errors.isTrusted ? true : false}
                                  />
                                )}
                              />
                              <Label htmlFor="check">{t("Terms of use")}</Label>
                            </div>
                          </div>
                          <div className="mt-1 d-flex justify-content-center">
                            <Button
                              className="btn btn-primary w-50"
                              type="submit"
                            >
                              {loading ? (
                                <div className="d-flex align-items-center gap-3 justify-content-center">
                                  <Spinner size={"sm"} />
                                  <div>{t("Sign Up")}</div>
                                </div>
                              ) : (
                                <div>{t("Sign Up")}</div>
                              )}
                            </Button>
                          </div>
                          <div className="mt-1 text-center d-flex align-items-center gap-2 justify-content-center">
                            <p className="mb-0">
                              {t("Already have an account ?")}
                            </p>
                            <Link
                              to="/login"
                              className="fw-semibold text-primary text-decoration-underline"
                            >
                              {t("Sign In")}
                            </Link>
                          </div>
                        </Form>
                      ) : (
                        <Form
                          onSubmit={handleSubmit(SubmitOtp)}
                          className="needs-validation d-flex flex-column gap-3"
                        >
                          <div>
                            <InputMasked
                              name={"otp"}
                              control={control}
                              errors={errors}
                              maskProps={{mask: "999 999"}}
                              inputProps={{
                                
                                placeholder: t("Enter confirmation code"),
                              }}
                            />
                          </div>

                          <div className="mt-4 d-flex justify-content-center">
                            <Button
                              className="btn btn-primary w-50"
                              type="submit"
                            >
                              {loadingOtp ? (
                                <div className="d-flex align-items-center gap-2 justify-content-center">
                                  <Spinner size={"sm"} />
                                  <div>{t("Next")}</div>
                                </div>
                              ) : (
                                <div>{t("Next")}</div>
                              )}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </div>
                  </CardBody>
                </Card>
                <CustomModal
                  isOpen={isModalOpen}
                  toggleModal={toggleModal}
                  title={t("Terms of use")}
                  onConfirm={handleOnConfirm}
                  content={
                    <div>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Nisi vitae laborum eos consectetur perferendis tempore
                      blanditiis earum molestiae, odit tempora debitis velit,
                      provident repellendus eveniet. Quia facere perferendis sit
                      error consequatur, soluta similique asperiores sed dolorem
                      quis delectus consequuntur neque nesciunt reprehenderit
                      unde nihil cupiditate corporis aperiam, maxime esse
                      molestiae et harum hic dolorum! Neque eius tenetur atque
                      modi quos, laborum suscipit inventore maxime? Fuga
                      laudantium saepe eos. Ducimus aliquid sit placeat quo
                      doloremque sed voluptate nam qui sunt perferendis, nisi
                      saepe modi veritatis dolorem adipisci voluptates hic a
                      perspiciatis nulla omnis atque dolores mollitia facilis
                      itaque. Temporibus, quia dolorum! Pariatur ut amet alias
                      consequatur sequi corrupti corporis labore nulla
                      voluptatibus. Soluta odio ad voluptatum eos molestias!
                      Totam mollitia odio distinctio optio omnis eius
                      perferendis provident iusto vero! Esse nam suscipit
                      reiciendis doloribus fugit, tenetur dolore sit assumenda
                      repellat deserunt cum natus dignissimos ipsam? Tempora ea
                      asperiores ad ratione dolorum veniam fugit eum eaque
                      facilis in repellendus id vel obcaecati expedita,
                      recusandae adipisci magni cumque, alias ullam minima ab
                      ipsam omnis doloremque? Eligendi odio, minus distinctio
                      quisquam consequatur aliquam fugit modi, ipsum rerum
                      soluta deleniti dignissimos sed omnis iure nostrum
                      similique, alias nisi recusandae quaerat! Facere animi
                      deserunt maxime in voluptate quidem placeat laborum non
                      velit dolores numquam delectus officia perferendis
                      repellendus, quas fugiat voluptatum voluptatem similique
                      quibusdam voluptates temporibus illum sint? Distinctio
                      saepe facilis ratione, ipsam optio minima fugit nisi
                      aliquam expedita blanditiis, repellendus doloribus
                      suscipit. Nam, dolorum amet laudantium repudiandae hic
                      culpa, rem expedita totam veritatis autem enim dignissimos
                      eligendi. Error facilis architecto nisi accusantium
                      provident eveniet veniam autem facere illo sint obcaecati
                      accusamus placeat, labore hic ullam dicta quibusdam earum.
                      Ducimus quos voluptates facilis libero laborum numquam
                      est? Quam nesciunt rerum nisi, beatae maxime ducimus,
                      similique facere quidem asperiores rem reiciendis mollitia
                      accusantium enim voluptates, placeat vitae omnis ipsam
                      cum! Temporibus, repellat, esse iure nam incidunt et
                      itaque earum quo iusto rem sequi, tenetur aliquam eligendi
                      laborum veritatis enim? Fugit vitae nostrum corrupti
                      molestiae at in quae possimus magnam ipsum explicabo. Rem
                      pariatur reprehenderit odit ea nam, placeat sit!
                      Perspiciatis ad ut, nam nostrum laboriosam nulla
                      voluptatibus, ullam architecto quisquam praesentium dolore
                      minus impedit quia in quae esse quaerat, iste molestias
                      ipsum rem laudantium quo itaque commodi. Ullam cumque
                      dignissimos impedit ratione ipsa natus, itaque minus dolor
                      recusandae saepe tempora delectus. Dicta soluta dolore
                      quas repellendus ea libero numquam, exercitationem unde
                      excepturi voluptatum maxime tenetur eaque nulla nam? Sit
                      rerum doloremque quo neque sint excepturi officia in dolor
                      voluptatem reprehenderit aliquam tempora minus suscipit
                      molestias, saepe temporibus consequatur voluptas iure.
                      Sequi, voluptatum non. Laborum harum dolore ipsam quae
                      doloremque consequatur, aliquam, itaque ratione, omnis
                      deleniti ex quos explicabo mollitia reiciendis earum
                      illum. Doloribus molestiae fuga veritatis quas
                      praesentium, earum, consequatur itaque sunt, voluptate
                      corrupti eos est atque fugit dolorum sint suscipit
                      aliquam? Et similique tenetur inventore natus, quas cum
                      hic veritatis, quo repudiandae ex commodi culpa aspernatur
                      illum laboriosam tempore odit provident perspiciatis!
                      Corrupti alias fugit et est beatae explicabo aspernatur
                      amet, sint delectus aliquam porro cupiditate!
                    </div>
                  }
                  footerText={
                    <div
                      className="d-flex gap-2 mt-3  justify-content-center "
                      style={{ fontSize: "16px", marginRight: "6rem" }}
                    >
                      <Controller
                        name="isTrusted"
                        control={control}
                        render={({ field }) => (
                          <ReactInput
                            {...field}
                            className="form-check-input"
                            type="checkbox"
                            id="modalCheck"
                            onChange={(e) => {
                              onChange();
                              handleOnConfirm(e);
                            }}
                            checked={check}
                          />
                        )}
                      />
                      <Label htmlFor="modalCheck">{t("Terms of use")}</Label>
                    </div>
                  }
                />
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Register;
