import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getProfileQuery } from "../queries";

const Navdata = () => {
  const history = useNavigate();
  const { t } = useTranslation();
  const { data } = useQuery({
    ...getProfileQuery(),
  });

  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isProducts, setIsProducts] = useState(false);
  const [isClients, setIsClients] = useState(false);
  const [isReview, setIsReview] = useState(false);
  // const [isApps, setIsApps] = useState(false);
  // const [isAuth, setIsAuth] = useState(false);
  // const [isPages, setIsPages] = useState(false);
  // const [isBaseUi, setIsBaseUi] = useState(false);
  // const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  // const [isForms, setIsForms] = useState(false);
  // const [isTables, setIsTables] = useState(false);
  // const [isCharts, setIsCharts] = useState(false);
  // const [isIcons, setIsIcons] = useState(false);
  // const [isMaps, setIsMaps] = useState(false);
  // const [isMultiLevel, setIsMultiLevel] = useState(false);

  // Apps
  // const [isEmail, setEmail] = useState(false);
  // const [isSubEmail, setSubEmail] = useState(false);
  // const [isEcommerce, setIsEcommerce] = useState(false);
  // const [isProjects, setIsProjects] = useState(false);
  // const [isTasks, setIsTasks] = useState(false);
  // const [isCRM, setIsCRM] = useState(false);
  // const [isCrypto, setIsCrypto] = useState(false);
  // const [isInvoices, setIsInvoices] = useState(false);
  // const [isSupportTickets, setIsSupportTickets] = useState(false);
  // const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
  // const [isJobs, setIsJobs] = useState(false);
  // const [isJobList, setIsJobList] = useState(false);
  // const [isCandidateList, setIsCandidateList] = useState(false);

  // Authentication
  // const [isSignIn, setIsSignIn] = useState(false);
  // const [isSignUp, setIsSignUp] = useState(false);
  // const [isPasswordReset, setIsPasswordReset] = useState(false);
  // const [isPasswordCreate, setIsPasswordCreate] = useState(false);
  // const [isLockScreen, setIsLockScreen] = useState(false);
  // const [isLogout, setIsLogout] = useState(false);
  // const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  // const [isVerification, setIsVerification] = useState(false);
  // const [isError, setIsError] = useState(false);

  // Pages
  // const [isLanding, setIsLanding] = useState(false);

  // Charts
  // const [isApex, setIsApex] = useState(false);

  // Multi Level
  // const [isLevel1, setIsLevel1] = useState(false);
  // const [isLevel2, setIsLevel2] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Products") {
      setIsProducts(false);
    }
    if (iscurrentState !== "Clients") {
      setIsClients(false);
    }
    if (iscurrentState !== "Review") {
      setIsReview(false);
    }
    // if (iscurrentState !== "Apps") {
    //   setIsApps(false);
    // }
    // if (iscurrentState !== "Auth") {
    //   setIsAuth(false);
    // }
    // if (iscurrentState !== "Pages") {
    //   setIsPages(false);
    // }
    // if (iscurrentState !== "BaseUi") {
    //   setIsBaseUi(false);
    // }
    // if (iscurrentState !== "AdvanceUi") {
    //   setIsAdvanceUi(false);
    // }
    // if (iscurrentState !== "Forms") {
    //   setIsForms(false);
    // }
    // if (iscurrentState !== "Tables") {
    //   setIsTables(false);
    // }
    // if (iscurrentState !== "Charts") {
    //   setIsCharts(false);
    // }
    // if (iscurrentState !== "Icons") {
    //   setIsIcons(false);
    // }
    // if (iscurrentState !== "Maps") {
    //   setIsMaps(false);
    // }
    // if (iscurrentState !== "MuliLevel") {
    //   setIsMultiLevel(false);
    // }
    // if (iscurrentState === "Widgets") {
    //   history("/widgets");
    //   document.body.classList.add("twocolumn-panel");
    // }
    // if (iscurrentState !== "Landing") {
    //   setIsLanding(false);
    // }
  }, [
    history,
    // iscurrentState,
    isDashboard,
    isProducts,
    isReview,
    // isApps,
    // isAuth,
    // isPages,
    // isBaseUi,
    // isAdvanceUi,
    // isForms,
    // isTables,
    // isCharts,
    // isIcons,
    // isMaps,
    // isMultiLevel,
  ]);
//commit
  const menuItems = [
    {
      label: "business",
      isHeader: true,
    },
    {
      id: "profile",
      label: t("Profile"),
      icon: " ri-account-box-fill",
      link: "/profile",
      name: "profile.list"
    },
    {
      id: "products",
      label: "Products",
      icon: "ri-store-2-fill",
      link: "/#",
      stateVariables: isProducts,
      click: function (e) {
        e.preventDefault();
        setIsProducts(!isProducts);
        setIscurrentState("Products");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "moderation",
          label: t("Moderation"),
          icon: "ri-store-2-fill",
          link: "/products-moderate",
          parentId: "products",
          name: "products2.moderate.list"
        },
        {
          id: "verifed",
          label: t("confirmed"),
          icon: "ri-store-2-fill",
          link: "/products",
          parentId: "products",
          name: "products2.list"
        },
      

        {
          id: "reject",
          label: t("rejected"),
          icon: "ri-store-2-fill",
          link: "/products-reject",
          parentId: "products",
          name: "products2.reject.list"
        },
      ],
    },
    {
      id: "",
      label: "Заявки",
      icon: "ri-survey-line",
      link: "/orders",
      name: "orders.list"
    },
    {
      id: "reserve",
      label: t("reserve"),
      icon: "ri-shopping-basket-fill",
      link: "/reserve",
      name: "reserve.list"
    },
    {
      id: "employees",
      label: t("Employees"),
      icon: "ri-group-line",
      link: "/employees",
      name: "employees.list"
    },

    {
      id: "clients",
      label: t("Clients"),
      icon: "ri-group-line",
      link: "/#",
      stateVariables: isClients,
      click: (e) => {
        e.preventDefault();
        setIsClients(!isClients);
        setIscurrentState("Clients");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "moderation",
          label: t("All"),
          icon: "ri-store-2-fill",
          link: "/clients",
          parentId: "clients",
          name: "clients.list"
        },
      ],
    },
    {
      id: "review",
      label: t("review"),
      icon: "ri-question-answer-line",
      link: "/#",
      stateVariables: isReview,
      click: (e) => {
        e.preventDefault();
        setIsReview(!isReview);
        setIscurrentState("Review");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "product-review",
          label: t("Products"),
          icon: "ri-store-2-fill",
          link: "/product-review",
          parentId: "review",
          name: "review.product.list"
        },
        {
          id: "user-review",
          label: t("Organization"),
          icon: "ri-store-2-fill",
          link: "/organization-review",
          parentId: "review",
          name: "review.organization.list"
        },
      ],
    },

    // {
    //   id: "contracts",
    //   label: "contracts",
    //   icon: "ri-store-2-fill",
    //   link: "/contracts",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("Contracts");
    //   },
    // },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
