/* eslint-disable comma-dangle */
import * as yup from "yup";
import { config } from "./config";
const isFoodSite = config.SITENAME === "food";
const schemaFieldTypes = {
  required: yup
    .string()
    .strict()
    .trim("spaces_error")
    .required("required_field_error"),
  default: yup
    .string()
    .strict()
    .trim("spaces_error")
    .required("required_field_error"),
  name: yup.string().strict().trim().required("required_field_error"),

  auth_password: yup
    .string()
    .required("required_field_error")
    .min(6, "min_6_chars_error"),
  retypePassword: yup
    .string()
    .test("passwords-match", "password_must_match", function test(value) {
      return this.parent.newPass === value;
    }),
  retypePassword2: yup
    .string()
    .test("passwords-match", "password_must_match", function test(value) {
      return this.parent.password === value;
    }),
  phone: yup
    .string()
    .required("required_field_error")
    .matches(
      /^\+998([- ])?(90|11|91|93|94|95|98|99|33|97|88|71|77|00|78|50)([- ])?(\d{3})([- ])?(\d{2})([- ])?(\d{2})$/,
      "invalid_phone_number"
    ),
    phone2: yup
    .string()
    .required("required_field_error")
    .matches(
      /^998([- ])?\d{2}([- ])?(\d{3})([- ])?(\d{2})([- ])?(\d{2})$/,
      "invalid_phone_number"
    ),
  
  phone998: yup
    .string()
    .required("required_field_error")
    .matches(
      /^(90|91|93|94|95|98|99|33|97|88|71|77|78|50)([- ])?(\d{3})([- ])?(\d{2})([- ])?(\d{2})$/,
      "invalid_phone_number"
    ),
  select: yup
    .object()
    .shape({
      label: yup.string(),
      id: yup.string(),
    })
    .nullable()
    .required("required_field_error"),
  multiselect: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      })
    )
    .min(1, "required_field_error"),

  email: yup.string().required("required_field_error").email("invalid_email"),
  birthday: yup
    .date()
    .required("required_field_error")
    .min(new Date("1970-01-01"), "invalid_birthday"),

  number: yup
    .number()
    .required("required_field_error")
    .typeError("required_field_error"),
  firstName: yup
    .string()
    .strict()
    .trim()
    .required("required_field_error")
    .matches(/^[a-zA-Zа-яА-Я\s']+$/, "spaces_error"),
  priceValue: yup
    .number()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .default(0)
    .typeError("required_field_error")
    .min(0, "Value must be greater than or equal to 0"),
  saleValue: yup
    .number()
    .nullable()
    .transform((value) => (value === "" ? 0 : value))
    .default(0)
    .typeError("required_field_error"),
    region: yup
    .array()
    .min(1, "At least one must be selected")
    .required("At least one must be selected"),
  accountNumber: yup
    .string()
    .transform(value => value.replace(/\s+/g, ''))
    .matches(/^\d{20}$/, "Account number must be exactly 20 digits")
    .required("Account number is required"),
  vatNumber: yup
    .string()
    .matches(/^\d{16}$/, "VAT number must be exactly 12 digits")
    .required("VAT number is required"),
  mfo: yup
    .string()
    .matches(/^\d{5}$/, "5 number")
    .required("Account number is required"),
  oked: yup
    .string()
    .matches(/^\d{5}$/, "5 number")
    .required("Account number is required"),
  organizationTin: yup
    .string()
    .matches(/^\d{9}$/, "9 number")
    .required("Account number is required"),
  isTrusted: yup
    .boolean()
    .oneOf([true], "You must agree to the terms of use")
    .required(),
    attrs: yup.array().of(
      yup.object().shape({
        value: yup.mixed().when(["isRequired", "isVisible", "isValueTranslated"], {
          is: (isRequired, isVisible, isValueTranslated) => isRequired && isVisible && !isValueTranslated,
          then: yup
            .string()
            .required("required_field_error") 
            .when("filter.filterType", {
              is: (val) => val === "Range" || val === "Price",
              then: yup.string().required("required_field_error"), 
            })
            .when("filter.filterType", {
              is: (val) => val === "SingleSelect",
              then: yup.string().required("required_field_error"), 
            })
            .when("filter.filterType", {
              is: (val) => val === "MultipleSelect",
              then: yup.string().required("At least one must be selected"), 
              otherwise: yup.string().nullable(),
            }),
          otherwise: yup.string().nullable(), 
        }),
      
      
        valueTranslations: yup.mixed().when(["isRequired", "isVisible"], {
          is: (isRequired, isVisible) => isRequired && isVisible,
          then: yup.mixed().when("isValueTranslated", {
            is: true,
            then: yup.object().shape({
              en: !isFoodSite
                ? yup.string().nullable()
                : yup.string().required("required_field_error"),
              ru: yup.string().required("required_field_error"),
              "uz-Cyrl-UZ": isFoodSite
                ? yup.string().nullable()
                : yup.string().required("required_field_error"),
              "uz-Latn-UZ": yup.string().required("required_field_error"),
            }),
            otherwise: yup.object().nullable(),
          }),
          otherwise: yup.object().nullable(),
        }),
      })
    ),
    files: yup
      .array()
      .min(1, "At least one must be selected")
      .required("At least one must be selected"),
    saleType: yup
      .array()
      .min(1, "At least one must be selected")
      .required("At least one must be selected"),
    name2: yup.object().shape({
      en: !isFoodSite
        ? yup.string().nullable()
        : yup.string().required("required_field_error"),
      ru: yup.string().required("required_field_error"),
      "uz-Latn-UZ": yup.string().required("required_field_error"),
      "uz-Cyrl-UZ": isFoodSite
        ? yup.string().nullable()
        : yup.string().required("required_field_error"),
    }),
    description2: yup.object().shape({
      en: !isFoodSite
        ? yup.string().nullable()
        : yup.string().required("required_field_error"),
      ru: yup.string().required("required_field_error"),
      "uz-Latn-UZ": yup.string().required("required_field_error"),
      "uz-Cyrl-UZ": isFoodSite
        ? yup.string().nullable()
        : yup.string().required("required_field_error"),
    }),
    saleType2:yup
    .number()
    .required("required_field_error")
    .test(
      "not-zero",
      "At least one must be selected",
      (value) => value !== 0
    ),
};

export default schemaFieldTypes;
