import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Label,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Input as ReactInput,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getProfileQuery,
  getReferenceFormalQuery,
  getRegionsQuery,
} from "../../../queries";
import {
  OrganizationAddressPut,
  OrganizationPost,
  OrganizationPut,
  OrganizationsBankPut,
  OrganizationsContactPost,
  OrganizationsContactPut,
  OrganizationsOwnerPut,
  getDistricts,
  getFileId,
  getOrganizations,
  uploadFile,
} from "../../../api";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { Input } from "../../../Components/atoms/input";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import "react-toastify/dist/ReactToastify.css";
import {
  formatAccountNumber,
  formatAccountNumber3,
  prettify,
  unformatAccountNumber,
} from "../ui/prettify";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { fileExtensionsTypeObj } from "../Components/file-format/format";
import InputMask from "../../../Components/atoms/inputMask";
import InputMasked from "../../../Components/atoms/inputMask";
import removeChars from "../../../Components/atoms/removeChars";
import OrganizationEditForm from "./Components/organization-edit-form";

const values = {
  organizationName: "",
  organizationTin: "",
  productSaleTypes: 0,
  type: null,
  vatNumber: "",
  id: null,
  address: "",
  districtId: null,
  regionId: null,
  serviceRegionsIds: [],
  ownerFullName: "",
  ownerPinfl: "",
  accountantFullName: "",
  accountantPinfl: "",
  email: "",
  phone: "",
  description: "",
  oked: "",
  servingBankName: "",
  accountNumber: "",
  mfo: "",
  files: null,
  latitude: "",
  longitude: ""
};

const OrganizationCreate = () => {
  const { t } = useTranslation();
  const schema = createSchema({
    organizationName: "name",
    type: "select",
    organizationTin: "number",
    vatNumber: "number",
    accountNumber: "accountNumber",
    mfo: "mfo",
    phone: "phone2",
    latitude: "number",
    longitude: "number",
    ownerFullName: "name",
    regionId: "select",
    districtId: "select",
    address: "name",
    oked: "oked",
    servingBankName: "name",
    email: "email",
    productSaleTypes: "saleType2",
    serviceRegionsIds: "region"
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useHookForm(values, schema);

  const [activeTab, setActiveTab] = useState(1);
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [selectedServiceRegions, setSelectedServiceRegions] = useState([]);
  const [saleTypeState, setSaleTypeState] = useState([]);
  const navigate = useNavigate();
  const clients = useQueryClient();


  function toggleTab(tab, value) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setActiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
  }

  const { data: profileData } = useQuery({
    ...getProfileQuery(),
  });
  const profileId = profileData?.result?.organizationId;
  const { data, isLoading } = useQuery({
    queryKey: ["organizations", profileId],
    queryFn: () => getOrganizations(profileId),
    enabled: !!profileId,
  });

  const { data: referenceFormal, isLoading: referenceFormalLoading } = useQuery(
    {
      ...getReferenceFormalQuery(),
    }
  );
  const { data: regions, isLoading: regionLoading } = useQuery({
    ...getRegionsQuery(),
  });

  const [regionId, setRegionId] = useState(
    regions?.result?.regions?.id || null
  );

  useEffect(() => {
    setRegionId(data?.result?.address.region?.id);
  }, [data]);

  const { data: districts, isLoading: districtsLoading } = useQuery({
    queryKey: ["districts", regionId],
    queryFn: () => getDistricts(regionId),
    enabled: !!regionId,
  });
  const filesId = data?.result?.organizationFiles?.find(
    (item) => item.type === 1
  )?.id;
  const { data: file, isLoading: fileLoading } = useQuery({
    queryKey: ["fileId", filesId],
    queryFn: () => getFileId(filesId),
    enabled: !!filesId,
  });

  const GetId = () => {
    if (file) {
      const fileType = file.type;
      const fileExtension = fileExtensionsTypeObj[fileType];
      const date = new Date();
      const fileName = `${Document.name}_${date
        .toLocaleDateString()
        .replace(/[:.-]/g, "_")}.${fileExtension}`;

      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };
  const handleServiceRegionChange = (regionId) => {
    setSelectedServiceRegions((prevSelectedRegions) => {
      const updatedRegions = prevSelectedRegions.includes(regionId)
        ? prevSelectedRegions.filter((id) => id !== regionId)
        : [...prevSelectedRegions, regionId];
  
      setValue("serviceRegionsIds", updatedRegions);
      return updatedRegions;
    });
  };

  const getInitialSaleTypeState = (productSaleTypes) => {
    const saleTypes = [];
    if (productSaleTypes & 1) saleTypes.push(1);
    if (productSaleTypes & 2) saleTypes.push(2);
    if (productSaleTypes & 4) saleTypes.push(4);
    if (productSaleTypes & 8) saleTypes.push(8);
    return saleTypes;
  };

  const RegionChange = (regionOption) => {
    setRegionId(regionOption.value);
    setValue("regionId", regionOption);
    setValue("districtId", null);
  };

  const DistrictChange = (districtOption) => {
    setValue("districtId", districtOption);
  };
  const handleSaleTypeChange = (type) => {
    setSaleTypeState((prevState) => {
      const updatedState = prevState.includes(type)
        ? prevState.filter((t) => t !== type)
        : [...prevState, type];
      setValue("productSaleTypes", updatedState.reduce((acc, t) => acc | t, 0));
      return updatedState;
    });
  };

  const calculateSaleType = () => {
    return saleTypeState.reduce((acc, type) => acc | type, 0);
  };
  let organizationIds = null;

  async function OrganizationsSubmit(res) {
    try {
      const response = await OrganizationPost(res);
      organizationIds = response?.result?.id;
    } catch (error) {
      const errorMessage = error?.data?.error?.errorMessage;

      toast(errorMessage, {
        theme: "colored",
        type: "error",
      });
    }
  }

  const Submit = async (res) => {
    const type = res.type !== null ? res.type.value : null;
    await OrganizationsSubmit({
      type,
      organizationName: res.organizationName,
      organizationTin: removeChars(res.organizationTin),
      vatNumber: removeChars(res.vatNumber),
      productSaleTypes: calculateSaleType(),
      latitude: res.latitude,
      longitude: res.longitude
    });
    const formData = new FormData();
    if (imageFile) {
      formData.append("type", 1);
      formData.append("files", imageFile);
    }
    const id = profileId;
    setLoading(true);

    const districtId = res.districtId ? res.districtId.value : null;
    const regionId = res.regionId ? res.regionId.value : null;
    const addressData = {
      id: organizationIds,
      address: res.address,
      districtId: districtId,
      regionId: regionId,
      serviceRegionsIds: selectedServiceRegions,
    };
    const contactData = {
      description: res.description,
      email: res.email,
      phone: removeChars(res.phone),
    };
    const accountantFullName = data?.result?.owner?.accountantFullName;
    const accountantPinfl = data?.result?.owner?.accountantPinfl;
    const ownerPinfl = data?.result?.owner?.ownerPinfl;
    const ownerData = {
      accountantFullName: accountantFullName,
      accountantPinfl: accountantPinfl,
      id: organizationIds,
      ownerFullName: res.ownerFullName,
      ownerPinfl: ownerPinfl,
    };

    const bankData = {
      id: organizationIds,
      servingBankName: res.servingBankName,
      oked: removeChars(res.oked),
      mfo: res.mfo,
      accountNumber: removeChars(res.accountNumber),
    };

    try {
      await OrganizationsContactPost(organizationIds, contactData);
      await OrganizationsBankPut(organizationIds, bankData);
      await OrganizationsOwnerPut(organizationIds, ownerData);
      await OrganizationAddressPut(organizationIds, addressData);
      if (imageFile) {
        await uploadFile(organizationIds, formData);
      }
      // toggleTab(activeTab + 1, 100);
      navigate("/profile");
      clients.invalidateQueries(["profile"]);
      navigate("/profile");
    } catch (error) {
      const errorMessage =
        error?.data?.error?.errorMessage ||
        error?.data?.errors?.id?.[0] ||
        "error";
      toast(errorMessage, {
        theme: "colored",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-content">
      <ToastContainer position="top-center" />
      {isLoading || referenceFormalLoading || fileLoading ? (
        <Spinner />
      ) : (
        <Col className="w-75">
          <Card>
            <CardBody>
              <div className="text-center pt-3 pb-4 mb-1">
                <h2 style={{ fontWeight: 600 }}>
                  {t("Merchant identification")}
                </h2>
                <p>
                  {t("Fill out all the fields and click the «Send» button")}
                </p>
              </div>

              <div className="progress-nav mb-4">
                <Progress value={progressbarvalue} style={{ height: "1px" }} />
                <Nav
                  className="nav-pills progress-bar-tab custom-nav"
                  role="tablist"
                >
                  <Button
                    className={classnames(
                      {
                        active: activeTab === 1,
                        done: activeTab <= 4 && activeTab >= 0,
                      },
                      "rounded-pill"
                    )}
                    // onClick={() => {
                    //   toggleTab(1, 0);
                    // }}
                    tag="button"
                  >
                    1
                  </Button>
                  <NavItem>
                    <NavLink
                      to="#"
                      id="pills-gen-info-tab"
                      className={classnames(
                        {
                          active: activeTab === 2,
                          done: activeTab <= 4 && activeTab > 1,
                        },
                        "rounded-pill"
                      )}
                      // onClick={() => {
                      //   toggleTab(2, 100);
                      // }}
                      tag="button"
                    >
                      2
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <OrganizationEditForm
                    data={data}
                    referenceFormal={referenceFormal}
                    regions={regions}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    Submit={handleSubmit(Submit)}
                    loading={loading}
                    GetId={GetId}
                    saleTypeState={saleTypeState}
                    districts={districts}
                    DistrictChange={DistrictChange}
                    setImageFile={setImageFile}
                    handleSaleTypeChange={handleSaleTypeChange}
                    regionLoading={regionLoading}
                    RegionChange={RegionChange}
                    districtsLoading={districtsLoading}
                    selectedServiceRegions={selectedServiceRegions}
                    handleServiceRegionChange={handleServiceRegionChange}
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      )}
    </div>
  );
};

export default OrganizationCreate;
