import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";

// Routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected } from "./AuthProtected";
import { useQuery } from "@tanstack/react-query";
import { getProfileInfoQuery } from "../queries";
import { Spinner } from "reactstrap";

const Index = () => {
  const { data, isLoading } = useQuery({
    ...getProfileInfoQuery(),
  });

  const checkAccess = (name) => {
    const accessList = data?.result?.access || [];
    if (name === "access" || name === "404") return true;
    return accessList.includes(name);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
          <Spinner size="lg" />
        </div>
      ) : (
        <Routes>
          {/* Public Routes */}
          {publicRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            />
          ))}

          {/* Auth Protected Routes */}
          {authProtectedRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                checkAccess(route.name) ? (
                  <AuthProtected>
                    <VerticalLayout>{route.component}</VerticalLayout>
                  </AuthProtected>
                ) : (
                  <Navigate to="/access" replace />
                )
              }
            />
          ))}

          {/* Fallback */}
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      )}
    </React.Fragment>
  );
};

export default Index;
